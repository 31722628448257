<template>
  <div>
    <el-dialog :title="title" :before-close="handleClose" :visible.sync="dialog" width="60%">
      <el-form :model="addEditForm" label-width="100px" ref="addEditForm">
        <el-row :gutter="24">
          <el-col :md="6" :sm="8">
            <el-form-item label="是否是同商品" prop="sameCommodityCode">
              <el-checkbox
                @change="handleCheckedCitiesChange"
                v-model="addEditForm.sameCommodityCode"
                :checked="addEditForm.sameCommodityCode===1? true:false"
                :true-label="1"
                :false-label="2"
                label="是"
                border
                size="medium"
                :disabled="this.editShow"
                style="margin:10px 30px  40px 30px"
              ></el-checkbox>
            </el-form-item>
          </el-col>

          <el-col :md="6" :sm="8" v-if="addEditForm.sameCommodityCode!=2">
            <el-form-item label="编码" prop="commodityCode" v-if="addEditForm.sameCommodityCode==2">
              <el-input
                placeholder="请输入编码"
                v-model="addEditForm.commodityCode"
                clearable
                @clear="handleQuery()"
              />
            </el-form-item>
            <el-form-item label="商品" prop="commodityCode" v-else>
              <el-select
                @change="AddCommodityCode"
                v-model="sameCommodityCodeFrom"
                filterable
                value-key="uniqueCode"
                placeholder="请选择"
                style="width:70%"
              >
                <el-option
                  v-for="item in commodityCodeList"
                  :key="item.uniqueCode"
                  :label="item.commodityName"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :md="6" :sm="8">
            <el-form-item label="类型英文" prop="serviceType" v-if="!this.editShow">
              <el-input
                placeholder="类型英文"
                v-model="addEditForm.serviceType"
                clearable
                @clear="handleQuery()"
                @input="serviceButton"
              />
            </el-form-item>
            <el-form-item label="类型英文" prop="serviceType" v-if="this.editShow">
              <el-input placeholder="类型英文" v-model="addEditForm.serviceType" disabled />
            </el-form-item>
          </el-col>

          <el-col :md="6" :sm="8">
            <el-form-item label="编码" prop="commodityCode">
              <el-input placeholder="请输入编码" v-model="addEditForm.commodityCode" disabled />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="24">
          <el-col :md="6" :sm="8">
            <el-form-item label="名字" prop="name">
              <el-input
                placeholder="请输入名字"
                v-model="addEditForm.name"
                prop="name"
                clearable
                @clear="handleQuery()"
              />
            </el-form-item>
          </el-col>
          <el-col :md="6" :sm="8">
            <el-form-item label="英文名字" prop="englishName">
              <el-input
                placeholder="请输入英文名字"
                v-model="addEditForm.englishName"
                prop="englishName"
                clearable
                @clear="handleQuery()"
              />
            </el-form-item>
          </el-col>
          <el-col :md="6" :sm="8">
            <el-form-item label="简称" prop="summary">
              <el-input
                placeholder="请输入简称"
                v-model="addEditForm.summary"
                prop="summary"
                clearable
                @clear="handleQuery()"
              />
            </el-form-item>
          </el-col>
          <el-col :md="6" :sm="8">
            <el-form-item label="是否一级菜单" prop="isMenu">
              <el-checkbox
                v-model="addEditForm.isMenu"
                :checked="addEditForm.isMenu===1? true:false"
                :true-label="1"
                :false-label="0"
              >是</el-checkbox>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="24">
          <el-col :md="6" :sm="8">
            <el-form-item label="是否参与体验" prop="isExperience">
              <el-switch
                v-model="addEditForm.isExperience"
                active-color="#ff4949"
                inactive-color="#13ce66"
                active-text="否"
                inactive-text="是"
                :active-value="2"
                :inactive-value="1"
              ></el-switch>
            </el-form-item>
          </el-col>

          <el-col :md="6" :sm="8">
            <el-form-item label="体验时长" prop="priority" v-if="addEditForm.isExperience!=2">
              <el-input-number
                placeholder="时长:天"
                v-model="addEditForm.priority"
                prop="address"
                clearable
                @clear="handleQuery()"
              />
            </el-form-item>
          </el-col>

          <el-col :md="6" :sm="8">
            <el-form-item label="类型" prop="type">
              <el-radio-group v-model="addEditForm.type">
                <el-radio :label="1">硬件</el-radio>
                <el-radio :label="2">软件</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :md="6" :sm="8">
            <el-form-item label="单位" prop="unit">
              <el-input
                placeholder="单位"
                v-model="addEditForm.unit"
                clearable
                @clear="handleQuery()"
              />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="24">
          <el-col :md="6" :sm="8">
            <el-form-item label="是否上架" prop="isGround">
              <el-switch
                v-model="addEditForm.isGround"
                active-color="#ff4949"
                inactive-color="#13ce66"
                active-text="否"
                inactive-text="是"
                :active-value="2"
                :inactive-value="1"
              ></el-switch>
            </el-form-item>
          </el-col>
          <el-col :md="6" :sm="8">
            <el-form-item label="标题" prop="titile">
              <el-input
                placeholder="请输入"
                v-model="addEditForm.titile"
                prop="titile"
                clearable
                @clear="handleQuery()"
              />
            </el-form-item>
          </el-col>
          <el-col :md="6" :sm="8">
            <el-form-item label="地址" prop="address">
              <el-input
                placeholder="请输入地址"
                v-model="addEditForm.address"
                prop="address"
                clearable
                @clear="handleQuery()"
              />
            </el-form-item>
          </el-col>

          <el-col :md="6" :sm="8">
            <el-form-item label="优先级" prop="priority" v-if="addEditForm.sameCommodityCode!=2">
              <el-input-number
                placeholder="请输入优先级"
                v-model="addEditForm.priority"
                prop="address"
                clearable
                @clear="handleQuery()"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :md="6" :sm="8">
            <el-form-item label="行业" prop="industry">
              <el-input
                placeholder="请输入行业"
                v-model="addEditForm.industry"
                prop="industry"
                clearable
                @clear="handleQuery()"
              />
            </el-form-item>
          </el-col>
          <el-col :md="6" :sm="8">
            <el-form-item label="范围" prop="scope">
              <el-input
                placeholder="请输入范围"
                v-model="addEditForm.scope"
                prop="scope"
                clearable
                @clear="handleQuery()"
              />
            </el-form-item>
          </el-col>
          <el-col :md="6" :sm="8">
            <el-form-item label="状态" prop="status">
              <el-radio v-model="addEditForm.status" label="2">正常</el-radio>
              <el-radio v-model="addEditForm.status" label="1">停用</el-radio>
            </el-form-item>
          </el-col>
          <el-col :md="6" :sm="8">
            <el-form-item label="价格" prop="price">
              <el-input-number
                placeholder="请输入"
                v-model="addEditForm.price"
                clearable
                @clear="handleQuery()"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :md="24" :sm="24">
            <el-form-item label="介绍" prop="content">
              <el-input
                placeholder="请输入介绍"
                v-model="addEditForm.content"
                prop="content"
                clearable
                @clear="handleQuery()"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :md="24" :sm="24">
            <el-form-item label="备注" prop="remark">
              <el-input
                placeholder="请输入备注"
                v-model="addEditForm.remark"
                prop="remark"
                clearable
                @clear="handleQuery()"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelForm('addEditForm')">取 消</el-button>
        <!-- @click="$refs.drawer.closeDrawer()" -->
        <el-button
          type="primary"
          @click="addFormButton()"
          :loading="loading"
        >{{ loading ? '提交中 ...' : '确 定' }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  selectproductByIdApi,
  deleteproductByIdApi,
  editproductApi,
  addproductApi,
  listproductApi
} from "@/api/system/product/product.js";
import { listDataProductApi } from "@/api/system/product/product.js";
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      editShow: false,
      title: null,
      addEditForm: {
        sameCommodityCode: "2",
        isMenu: "2",
        isExperience: 2,
        isGround: 2
      },
      timer: null,
      ids: null,
      attribute: 1,
      productCode: null,
      commodityCodeList: [],
      commodityCodes: null,
      sameCommodityCodeFrom: {},
      caType: 1
    };
  },
  methods: {
    show(id, type, attribute, productCode, commodityCode) {
      this.dialog = true;
      if (type == 2) {
        this.ids = id;
        this.selectByIdButton(id);
        this.editShow = true;
        this.title = "编辑";
      }
      if (type == 1) {
        this.title = "新增商品";
      }
      this.caType = type;
      this.attribute = attribute;
      this.productCode = productCode;
      this.commodityCodes = commodityCode;
      this.addEditForm.commodityCode = productCode;
    },
    handleClose(done) {
      if (this.loading) {
        return;
      }
      this.$confirm("关闭")
        .then(_ => {
          this.cancelForm();
          this.dialog = false;
        })
        .catch(_ => {});
    },
    cancelForm(formName) {
      this.loading = false;
      this.dialog = false;
      this.editShow = false;
      this.$refs["addEditForm"].resetFields();
      this.$parent.selectDataListButton();
    },
    addFormButton() {
      this.addEditForm.attribute = this.attribute;
      if (this.productCode != null) {
        this.addEditForm.productCode = this.productCode;
      }
      if (this.caType == 1) {
        this.addEditForm.id;
        addproductApi(this.addEditForm).then(res => {
          this.cancelForm();
        });
      }
      if (this.caType == 2) {
        editproductApi(this.addEditForm).then(res => {
          this.cancelForm();
        });
      }
    },
    //查询详情
    selectByIdButton() {
      selectproductByIdApi(this.ids).then(res => {
        this.addEditForm = res.result;
      });
    },
    addDialogClosed() {
      // 对整个表单进行重置，将所有字段值重置为初始值并移除校验结果
      this.$refs["addEditForm"].resetFields();
    },
    AddCommodityCode(value) {
      var codeButton = this.addEditForm.sameCommodityCode;

      var oldNameFrom = value;
      this.addEditForm = oldNameFrom;
      this.addEditForm.sameCommodityCode = codeButton;
    },
    commodityCodesListButton() {
      listDataProductApi({ attribute: 2, productCode: this.productCode }).then(
        res => {
          this.commodityCodeList = res.result;
        }
      );
    },
    handleCheckedCitiesChange(value) {
      console.log("log", value);
      if (value == 1) {
        this.commodityCodesListButton();
      }
    },
    serviceButton() {
      this.addEditForm.commodityCode =
        this.productCode + "-" + this.addEditForm.serviceType;
      console.log("==", this.addEditForm.commodityCode);
    }
  }
};
</script>
<style scoped>
</style>



